/*NEW ELEMENTS*/
.input-agis-block {
    display: inline-block;
    padding: .25rem .5rem;

    height: 35px;
    
    color: #464a4e;
    font-size: 14px;
    line-height: 36px;
    
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
}

.textarea-agis-block {
    display: inline-block;
    padding: .25rem .5rem;

    /* height: 40px; */
    
    color: #464a4e;
    font-size: 14px;
    line-height: 15px;
    
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
}

/*BUTTON*/
/* .btn-agis {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #FFFFFF;
    background-color: #2A5679;
    border-color: #2A5679;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis:hover {
    background-color: #346B91;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis:active {
    background-color: #4184B4;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-light {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis-light:hover {
    background-color: #e2e6ea;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-light:active {
    background-color: #e2e6ea;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
} */

/* .btn-agis-primary {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    background-color: #cce5ff;
    border-color: #b8daff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis-primary:hover {
    background-color: #b8daff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-primary:active {
    background-color: #b8daff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
} */

/* .btn-agis-success {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    background-color: #d4edda;
    border-color: #c3e6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis-success:hover {
    background-color: #c3e6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-success:active {
    background-color: #c3e6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-warning {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    background-color: #f9edbe;
    border-color: #f0c36d;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis-warning:hover {
    background-color: #f0c36d;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-warning:active {
    background-color: #f0c36d;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-danger {
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    color: #333333;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0.2rem;
}

.btn-agis-danger:hover {
    background-color: #f5c6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.btn-agis-danger:active {
    background-color: #f5c6cb;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
} */

.btn-agis-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
    min-width: 100px;
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/*TABLE*/
.table-agis {
    background-color: white;
    font-size: 12px;
}

.table-agis thead {
    background-color: #eee;
}

.table-agis th {
    padding: .35rem;
    border-bottom: 2px solid #ced4da !important;
}

.table-agis td {
    padding: .35rem;
}

/*CARD*/
.agis-card {
    font-size: 12px;
    padding: .3rem .35rem;
}

.agis-card .header {
    font-weight: 600;
    font-size: 12px;
    padding: .35rem;
    background-color: #eee;
    /* border-bottom: 2px solid #ced4da; */
}

.agis-card .body {
    /* display: flex; 
    align-items: center; */

    font-size: 12px;
    padding: .3rem .35rem;
    /* min-height: 40px; */
}

/*INPUT*/
.agis-input-label {
    padding-top: 3px;
}

@media screen and (min-width: 768px) {
    .agis-input-label {
        text-align: right;
        padding-top: 3px;
    }
}

.agis-input-description {
    font-size: 11px;
}

.agis-input {
    display: block;
    width: 100%;
    
    padding: .25rem .45rem;
    font-size: 12px;
    line-height: 1.5;
    
    /* padding: .1rem .35rem;
    font-size: 12px;
    line-height: 1.5; */


    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

select.agis-input {
    height: 23px;
}

.progress {
    background-color: #dddddd;
}