/*LIGHT*/
.btn-agis-block {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #464a4e;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.btn-agis-block:focus {
    outline:0;
}

.btn-agis-block:hover {
    background-color: #F6F6F6;
}

.btn-agis-block:active {
    background-color: #D1D1D1;
}

.btn-agis-light-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #868e96; 
    font-size: 14px;
    line-height: 1.5;
    
    background-color: transparent;
    border: 0px;
    border-radius: 20px;  
}

.btn-agis-light-outline:focus {
    outline:0;
}

.btn-agis-light-outline:hover {
    color: #464a4e;
    background-color: #f4f4f4;
}

.btn-agis-light-outline:active {
    color: #464a4e;
    background-color: #ebebeb;
}

.bc-grey {
    background-color: #f4f4f4;
}

/*HOVER*/
.btn-agis-hover {
    padding: 0px 20px;

    height: 40px;
    min-width: 0px;
    
    color: #fff; 
    font-size: 14px;
    line-height: 1.5;
    
    background-color: rgba(0,0,0, 0.4);
    border: 2px solid #DFE1E5;
    border-radius: 22px;  
}

.btn-agis-hover:focus {
    outline:0;
}

.btn-agis-hover:hover {
    background-color: rgba(0,0,0, 0.7);
}

.btn-agis-hover:active {
    background-color: rgba(0,0,0, 0.8);
}

/*PRIMARY*/
/* .btn-agis-primary {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #4184b4;
    border: 1px solid #4184b4;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.btn-agis-primary:focus {
    outline:0;
}

.btn-agis-primary:hover {
    background-color: #528FBA;
}

.btn-agis-primary:active {
    background-color: #6199C0;
} */

.btn-agis-primary-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #004085;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #cce5ff;
    border: 0px;
    border-radius: 20px;
}

.btn-agis-primary-outline:focus {
    outline:0;
}

.btn-agis-primary-outline:hover {
    background-color: #b8daff;
}

.btn-agis-primary-outline:active {
    background-color: #92befb;
}

/*SECONDARY*/
.btn-agis-secondary-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #868e96; 
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #f4f4f4;
    border: 0px;
    border-radius: 20px;  
}

.btn-agis-secondary-outline:focus {
    outline:0;
}

.btn-agis-secondary-outline:hover {
    color: #464a4e;
    background-color: #ebebeb;
}

.btn-agis-secondary-outline:active {
    color: #464a4e;
    background-color: #dddddd;
}

/*SUCCESS*/
.btn-agis-success {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #1ba261;
    border: 1px solid #1ba261;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.btn-agis-success:focus {
    outline:0;
}

.btn-agis-success:hover {
    background-color: #5bd48f;
}

.btn-agis-success:active {
    background-color: #5bd48f;
}

.btn-agis-success-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #155724;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #d4edda; 
    border: 0px;
    border-radius: 20px;
}

.btn-agis-success-outline:focus {
    outline:0;
}

.btn-agis-success-outline:hover {
    background-color: #c3e6cb;
}

.btn-agis-success-outline:active {
    background-color: #96d4b1;
}

/*WARNING*/
.btn-agis-warning {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #ffcf49;
    border: 1px solid #ffcf49;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.btn-agis-warning:focus {
    outline:0;
}

.btn-agis-warning:hover {
    background-color: #ffff7b;
}

.btn-agis-warning:active {
    background-color: #ffff7b;
}

.btn-agis-warning-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #856404;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #fff3cd;
    border: 0px;
    border-radius: 20px;
}

.btn-agis-warning-outline:focus {
    outline:0;
}

.btn-agis-warning-outline:hover {
    background-color: #ffeeba;
}

.btn-agis-warning-outline:active {
    background-color: #ffe494;
}

/*DANGER*/
.btn-agis-danger {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #e16156;
    border: 1px solid #e16156;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);  
}

.btn-agis-danger:focus {
    outline:0;
}

.btn-agis-danger:hover {
    background-color: #ff9283;
}

.btn-agis-danger:active {
    background-color: #ff9283;
}

.btn-agis-danger-outline {
    padding: .25rem .5rem;

    height: 40px;
    min-width: 100px;
    
    color: #721c24;
    font-size: 14px;
    line-height: 1.5;
    
    background-color: #f8d7da;
    border: 0px;
    border-radius: 20px;
}

.btn-agis-danger-outline:focus {
    outline:0;
}

.btn-agis-danger-outline:hover {
    background-color: #f5c6cb;
}

.btn-agis-danger-outline:active {
    background-color: #eea4a4;
}