.card-agis {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    padding-top: .0rem;
    padding-bottom: .0rem;
    border: 1px solid #ebebeb;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);

    font-size: 12px;
    padding: .3rem .35rem;
    min-height: 40px;
}

.card-agis.clickable {
    text-decoration: none;
    color: #54585a;
    cursor: pointer;
    color: #54585a;
}

.card-agis.clickable:hover {
    background-color: #f9f9f9;
    text-decoration: none;
    color: #54585a;
}

.card-agis.clickable:active {
    background-color: #e9ecef;
    text-decoration: none;
    color: #54585a;
}

.agis-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    padding: .5rem;

    border-radius: 0px;
    /* border: 1px solid #ebebeb; */
    /* box-shadow: 0 1px 1px rgba(0,0,0,.15); */
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 1px; */
    box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1);

    font-size: 12px;
    padding: .3rem .35rem;
    min-height: 40px;

    background-color: #fff;

    border-radius: 6px;
}

.agis-card.bordered  {
    border: 1px solid #ebebeb;
    /* box-shadow: 0 1px 1px rgba(0,0,0,.15); */
    background-color: #f7f7f7;
}

.agis-card.noselect  {
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.agis-card.clickable {
    text-decoration: none;
    cursor: pointer;
    color: #54585a;
}

.agis-card.clickable:hover {
    background-color: #e9ecef;
    text-decoration: none;
    color: #54585a;
}