.question-input {
    height: 40px;
    width: 100%;
    padding: .5rem .5rem;
    color: #464a4e;
    font-size: 14px;
    line-height: 15px;
    

    /* background-color: #F7F7F7; */
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ebebeb;

    box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 2px 3px 0 rgba(0,0,0,.1);
}

.question-input::placeholder {
    color: #AFB7BF;
}

